import {
  ElasticAppSearchQuery,
  ElasticAppSearchQuerySort,
  SortDirection,
} from '@elastic/app-search-javascript'

/**
 * For some reason urls is indexed sometime with prefix and sometimes without by Litium.
 * This method will normalize the urls
 */
export const parseUrlFromElastic = (url: string, locale: string) => {
  if (!url.startsWith('/')) {
    url = '/' + url
  }
  const newUrl = url.replace('/' + locale + '/', '/')
  return newUrl
}

export const parseSort = (
  sort?: string | null,
): ElasticAppSearchQuerySort | undefined => {
  if (!sort) return undefined
  const [sortField, dirString] = sort.split('.')
  const dir: SortDirection = dirString == 'asc' ? 'asc' : 'desc'
  return { [sortField]: dir }
}

export function buildProductQuery(vIds: string[], bpIds: string[]) {
  const productQuery: ElasticAppSearchQuery = {
    query: '',
    group: {
      field: 'bp_id',
      sort: {
        in_stock: 'desc',
      },
      collapse: true,
    },
    page: {
      size: 1000,
    },
    filters: {
      none: [
        {
          type: 'category',
        },
      ],
      any: [],
    },
  }

  if (vIds.length > 0) {
    productQuery.filters!.any!.push({
      v_id: vIds,
    })
  }
  if (bpIds.length > 0) {
    productQuery.filters!.any!.push({
      bp_id: bpIds,
    })
  }
  return productQuery
}

export function buildCategoryQuery(cIds: string[]) {
  const query: ElasticAppSearchQuery = {
    query: '',
    page: {
      size: 1000,
    },
    filters: {
      all: [
        {
          type: ['category'],
        },
      ],
      any: [
        {
          category_id: cIds,
        },
      ],
    },
  }
  return query
}

export const parseId = (id: string) => {
  const i = id.indexOf(', ')
  if (i > 0) {
    return id.substring(0, i)
  }
  return id
}
