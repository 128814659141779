/* eslint-disable no-param-reassign */
import * as elatic from '@elastic/app-search-javascript'
import type { ElasticAppSearchClient } from '@elastic/app-search-javascript'
import { Dictionary } from 'utils/dictionary'

const client: Dictionary<ElasticAppSearchClient> = {}

type SearchIndexType = 'products-web' | 'cms'

export const productSortOptionsSearch = [
  '_score.desc',
  'recommended.asc',
  'bp_name.asc',
  'bp_name.desc',
  'price.asc',
  'price.desc',
] as const

export const productSortOptionsCategory = [
  'recommended.asc',
  'bp_name.asc',
  'bp_name.desc',
  'price.asc',
  'price.desc',
] as const

export type ProductSortOptionsType = (typeof productSortOptionsSearch)[number]

export const defaultProductSortOptionCategory: ProductSortOptionsType =
  'recommended.asc'

export const defaultProductSortOptionSearch: ProductSortOptionsType =
  'recommended.asc'

export const contentSortOptions = [
  '_score.desc',
  'title.asc',
  'title.desc',
  'date.asc',
  'date.desc',
] as const
export type ContentSortOptionsType = (typeof contentSortOptions)[number]
export const defaultContentSortOption: ContentSortOptionsType = '_score.desc'

export const getSearchClient = (
  locale: string,
  index: SearchIndexType = 'products-web',
) => {
  const id = `${index}-${locale}`
  try {
    let engine = client[id]
    if (!engine) {
      const config = {
        searchKey: process.env.NEXT_PUBLIC_ELASTIC_SEARCH_KEY as string,
        endpointBase: process.env.NEXT_PUBLIC_ELASTIC_ENDPOINT_BASE as string,
        engineName: `${process.env.NEXT_PUBLIC_ELASTIC_PREFIX}-${id}`,
      }
      client[id] = engine = elatic.createClient(config)
    }
    return engine
  } catch (e) {
    console.error(e)
    throw e
  }
}
