import {
  ElasticAppSearchQueryGroup,
  SearchFieldQuery,
} from '@elastic/app-search-javascript'

export const productGroup: ElasticAppSearchQueryGroup = {
  field: 'group',
  sort: { main: 'desc' },
  collapse: true,
}

export const productSearchFields: SearchFieldQuery = {
  category_id: {
    weight: 4,
  },
  v_id: {
    weight: 10,
  },
  bundle_articlecode: {
    weight: 20,
  },
  seo_tags: {
    weight: 20,
  },
}
